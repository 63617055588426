import { Injectable } from '@angular/core';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {interval, Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class OauthService {
  private refreshSubscription: Subscription | null = null;
  constructor(private oauthService: OAuthService, private authenticationService: AuthenticationService) {
    this.configure();
  }

  private configure() {
    const authConfig: AuthConfig = {
      // https://auth-int.alphabet.com/auth/realms/internet-c2b/.well-known/openid-configuration
      issuer: environment.issuer,
      loginUrl: environment.loginUrl,
      tokenEndpoint: environment.tokenEndpoint,
      clientId: 'DriveAlphabetPL',
      redirectUri: window.location.origin + '/oauth-callback',
      responseType: 'code',
      scope: '',
      showDebugInformation: true, // for debugging purposes
    };

    console.log("OAUTH: load config")
    this.oauthService.configure(authConfig);
    console.log("OAUTH: loadDiscoveryDocumentAndTryLogin")
    //this.oauthService.loadDiscoveryDocumentAndTryLogin();

    this.oauthService.tryLoginCodeFlow();

    console.log("OAUTH: end load config")
  }

  public login() {
    console.log("OAUTH: init code flow")
    this.oauthService.initCodeFlow();
  }

  public logOut() {
    this.stopPeriodicRefresh();
    this.oauthService.logOut();
  }

  public getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  public async tryLogin(): Promise<boolean> {
    await this.oauthService.loadDiscoveryDocument();
    return this.oauthService.tryLogin();
  }

  // refresh token

  public initPeriodicRefresh() {
    // Check if the user is logged in and has a sub claim
    const identityClaims = this.oauthService.getIdentityClaims();
    if (identityClaims && identityClaims['sub']) {
      this.startPeriodicRefresh();
    }
  }

  private startPeriodicRefresh() {
    // Cancel any existing refresh intervals
    this.stopPeriodicRefresh();

    // Start a new interval for refreshing the token
    const refreshInterval = environment.refreshTokenInterval * 60 * 1000; //  minutes in milliseconds
    console.log(refreshInterval);
    this.refreshSubscription = interval(refreshInterval).subscribe(() => {
      console.log('Attempting to refresh token...');
      this.refreshToken();
    });
  }

  public refreshToken() {
    // Assuming your OAuthService instance has a method to refresh the token
    this.oauthService.refreshToken().then(() => {
      // Retrieve the refreshed token
      const newToken = this.oauthService.getAccessToken();

      // Get the current user from local storage
      const currentUserJson = localStorage.getItem('currentUser');
      if (currentUserJson) {
        const currentUser = JSON.parse(currentUserJson);

        // Update the token in the currentUser object
        currentUser.token = newToken;

        // Save the updated currentUser back to local storage
        localStorage.setItem('currentUser', JSON.stringify(currentUser));

        // Notify any subscribers about the token refresh
        this.authenticationService.refreshUser(currentUser);
      }
    }).catch(error => {
      console.error('Error refreshing token:', error);
      // Handle token refresh error (e.g., re-authentication might be needed)
    });
  }

  public stopPeriodicRefresh() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
  }
  
}
