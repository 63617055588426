export const environment = {
  production: true,
  baseUrl: 'https://drive.alphabet.pl/',
  apiUrl: 'https://drive-api.alphabet.pl/api',
  storageTokenName: 'driveUserToken',
  identityLogoutRedirect: 'https://360fp.alphabet.com/portal-app/pl-PL/360/dashboard',
  refreshTokenInterval: 1, // in minutes
  issuer: 'https://auth.alphabet.com/auth/realms/internet-c2b',
  loginUrl: 'https://auth.alphabet.com/auth/realms/internet-c2b/protocol/openid-connect/auth',
  tokenEndpoint: 'https://auth.alphabet.com/auth/realms/internet-c2b/protocol/openid-connect/token',
};

