import { Injectable } from '@angular/core';
import { environment } from '../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SetPasswordRequest } from '../_models/account/set-password-request';
import {BehaviorSubject, Observable} from 'rxjs';
import { NewDriverRequest } from '../_models/account/new-driver-request';
import { DriverDto } from '../_models/dtos/driver-dto';
import { UpdateDataAccountFMRequest } from '../_models/account/update-data-account-FM-request';
import {UpdateAccountRequest} from '../_models/account/update-account-request';
import {RegisterDriverMultiRequest} from '../_models/account/register-driver-multi-request';
import {UserDtoV2} from '../_models/account/user-dto-v2';
import {UserDto} from '../_models/account/user-dto';
import { RoleDto } from '../_models/account/role-dto';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  apiUrl = environment.apiUrl;
  observableCanRegister: BehaviorSubject<boolean>;
  canRegister: boolean;

  constructor(private http: HttpClient) {
    this.observableCanRegister = new BehaviorSubject<boolean>(this.canRegister);
  }

  verifyUser(code: string) {
    return this.http.get(`${this.apiUrl}/Account/Verify?code=${code}`);
  }

  setPassword(setPasswordRequest: SetPasswordRequest) {
    return this.http.post(`${this.apiUrl}/Account/SetPassword`, setPasswordRequest);
  }

  getUserById(userId: any) {
    return this.http.post(`${this.apiUrl}/Account/GetUserById`, userId);
  }

  getUserByVerificationCode(code: string) {
    return this.http.post(`${this.apiUrl}/Account/GetUserByVerificationCode`, code);
  }

  registerDriver(newDriver: NewDriverRequest) {
    return this.http.post(`${this.apiUrl}/Account/RegisterDriver`, newDriver);
  }

  registerDriverMulti(newDriver: RegisterDriverMultiRequest) {
    return this.http.post(`${this.apiUrl}/Account/RegisterDriverMulti`, newDriver);
  }

  switchCanRegister(option: boolean) {
    this.observableCanRegister.next(option);
  }

  getMyData() {
    return this.http.get(`${this.apiUrl}/Account/GetMyData`);
  }

  emailExists(email: string) {
    return this.http.post(`${this.apiUrl}/Account/CheckEmail`, { email: email });
  }

  resetPassword(email: string) {
    return this.http.post(`${this.apiUrl}/Account/ResetPassowrd`, { email: email });
  }

  verifyEmail(code: string) {
    return this.http.get(`${this.apiUrl}/Account/VerifyEmail?code=${code}`);
  }

  updateMyData(data: UpdateAccountRequest) {
    return this.http.post(`${this.apiUrl}/Account/UpdateMyData`, data);
  }

  updateDataAccountFM(data: UpdateDataAccountFMRequest) {
    return this.http.post(`${this.apiUrl}/Account/UpdateDataAccountFM`, data);
  }

  getUserBySubject(sub: string): Observable<UserDtoV2> {
    return this.http.get<UserDtoV2>(`${this.apiUrl}/Account/GetUserBySub?sub=${sub}`);
  }

  getUserByEmail(email: string): Observable<UserDtoV2> {
    return this.http.get<UserDtoV2>(`${this.apiUrl}/Account/GetUserByEmail?email=${email}`);
  }

  assignSubjectToAccount(email: string, sub: string): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/Account/AssignSubjectToAccount?email=${email}&sub=${sub}`);
  }

  getUserRoles(userId: any): Observable<RoleDto[]> {
    return this.http.get<RoleDto[]>(`${this.apiUrl}/Account/GetUserRoles?id=${userId}`);
  }
  loginRemap(email: string) {
    return this.http.post(`${this.apiUrl}/Account/TokenRemap`, { email: email });
  }
}
