import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppRoutingModule} from './app.routing';
import {ComponentsModule} from './components/components.module';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';

import {AppComponent} from './app.component';

import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {TestValuesService} from './_services/test-values.service';
import {DriverService} from './_services/driver.service';
import {AuthenticationService} from './_services/authentication.service';
import {AuthGuard} from './_guards/auth.guard';
import {AuthInterceptor} from './_interceptor/auth.interceptor';
import {AccountService} from './_services/account.service';
import {CarsService} from './_services/cars.service';
import {LanguageService} from './_services/language.service';
import {WorkshopService} from './_services/workshop.service';
import {ToastService} from './_services/toast.service';
import {CitiesService} from './_services/cities.service';
import {RegionsService} from './_services/regions.service';
import {DatePipe} from '@angular/common';
import {RoleGuard} from './_guards/role.guard';

import {ButtonModule, ButtonGroupModule} from '@progress/kendo-angular-buttons';
import {BrowserModule} from '@angular/platform-browser';
import {NotifyService} from './_services/notify.service';
import {FleetService} from './_services/fleet.service';
import {RelocationsService} from './_services/relocations.service';
import {CompanyService} from './_services/company.service';
import {DialogModule, DialogsModule} from '@progress/kendo-angular-dialog';
import {CarDriverService} from './_services/car-driver.service';
import {TyreOrdersService} from './_services/tyre-orders.service';
import {ConfigurationService} from './_services/configuration.service';
import {JwtInterceptor} from './_guards/jwt.interceptor';
import {ErrorInterceptor} from './_guards/error.interceptor';
import {UsersService} from './_services/users.service';
import {ExcelExportModule} from '@progress/kendo-angular-excel-export';
import {MessagesService} from './_services/messages.service';
import {DepositsService} from './_services/deposits.service';
import {NetworkService} from './_services/network.service';
import {OrdersService} from './_services/orders.service';
import {TreeViewModule} from '@progress/kendo-angular-treeview';
import {ProgressBarModule} from '@progress/kendo-angular-progressbar';
import {LoaderModule} from '@progress/kendo-angular-indicators';
import {PopoverModule, TooltipsModule} from '@progress/kendo-angular-tooltip';
import {OAuthModule} from 'angular-oauth2-oidc';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ButtonModule,
    ButtonGroupModule,
    ExcelExportModule,
    TreeViewModule,
    ProgressBarModule,
    DialogsModule,
    LoaderModule,
    TooltipsModule,
    OAuthModule.forRoot()
  ],
  declarations: [AppComponent, AdminLayoutComponent],
  providers: [
    TestValuesService,
    DriverService,
    AuthenticationService,
    AccountService,
    CarsService,
    LanguageService,
    WorkshopService,
    ToastService,
    CitiesService,
    RegionsService,
    FleetService,
    NotifyService,
    RelocationsService,
    CompanyService,
    CarDriverService,
    TyreOrdersService,
    ConfigurationService,
    MessagesService,
    DepositsService,
    NetworkService,
    DatePipe,
    AuthGuard,
    DialogModule,
    UsersService,
    OrdersService,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
