import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../src/environments/environment';
import {UserCredentials} from '../_models/user-credentials';
import {map} from 'rxjs/operators';
import {UserClaims} from '../_models/user-claims';
import {BehaviorSubject, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationService} from './configuration.service';
import jwt_decode from 'jwt-decode';
import {UserDto} from '../_models/account/user-dto';
import {UserDtoV2} from '../_models/account/user-dto-v2';
import { RoleDto } from '../_models/account/role-dto';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserDto>;
  public currentUser: Observable<UserDto>;

  baseUrl = environment.baseUrl;

  // old version
  private user: UserClaims;
  observableUser: BehaviorSubject<UserClaims>;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private router: Router,
    private configurationService: ConfigurationService,
    private route: ActivatedRoute,
    private accountService: AccountService
  ) {
    // this.user = new UserClaims(null);
    // this.observableUser = new BehaviorSubject<UserClaims>(this.user);

    this.currentUserSubject = new BehaviorSubject<UserDto>(JSON.parse(localStorage.getItem('currentUser')));

    this.currentUser = this.currentUserSubject.asObservable();
  }

  public refreshUser(user: UserDto) {
    this.currentUserSubject.next(user);
  }

  eventChange() {
    this.configurationService.getSystemActive().subscribe(
      (result: boolean) => {
        if (result) {
          if (this.user !== null) {
            this.SetUserLanguage(this.user.language);
          }
          this.observableUser.next(this.user);
        } else {
          if (this.user !== null && this.user.roles !== null && this.user.roles !== undefined) {
            if ('Admin' in this.user.roles) {
              if (this.user !== null) {
                this.SetUserLanguage(this.user.language);
              }
              this.observableUser.next(this.user);
            } else {
              this.user = null;
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );

    // if (this.user !== null) {
    //   this.SetUserLanguage(this.user.language);
    // }
    // this.observableUser.next(this.user);
  }

  // public get currentUserValue(): UserClaims {
  //   return this.observableUser.value;
  // }

  ValidateUser(userCredentials: UserCredentials) {
    const userData = 'username=' + userCredentials.username + '&password=' + userCredentials.password + '&grant_type=password';
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.post(this.baseUrl + '/token', userData, {headers: reqHeader}).pipe(map(res => res));
  }

  // LogIn(userCredentials: UserCredentials) {
  //   const userData = 'username=' + userCredentials.username + '&password=' + userCredentials.password + '&grant_type=password';
  //   const reqHeader = new HttpHeaders({
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   });
  //   this.http
  //     .post(this.baseUrl + '/token', userData, { headers: reqHeader })
  //     .pipe(map(res => res))
  //     .subscribe(
  //       (data: any) => {
  //         // checking configuration if system is active
  //         localStorage.setItem(environment.storageTokenName, data.access_token);
  //         // this.GetClaims();
  //         this.eventChange();
  //       },
  //       (error: any) => {
  //         if (error.error === 'user_not_found{"error":"invalid_grant"}') {
  //           this.ErrorUser('user_not_found');
  //         } else if (error.error === 'wrong_password{"error":"invalid_grant"}') {
  //           this.ErrorUser('wrong_password');
  //         }
  //       }
  //     );
  // }

  // GetClaims() {
  //   return this.http.get(this.baseUrl + '/api/Account/GetUserClaims').subscribe(
  //     (data: any) => {
  //       const roles: Role[] = JSON.parse(data.roles);
  //       const language: string = data.language;
  //       const userClaims = new UserClaims({
  //         id: data.id,
  //         email: data.email,
  //         firstName: data.firstName,
  //         lastName: data.lastName,
  //         roles: roles,
  //         language: language.toLowerCase(),
  //         fullName: `${data.firstName} ${data.lastName}`,
  //         errorMessage: null,
  //         error: false
  //       });
  //       this.user = userClaims;
  //       this.eventChange();
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }

  // GetUserClaims(): UserClaims {
  //   if (localStorage.getItem(environment.storageTokenName) != null) {
  //     this.GetClaims();
  //     return this.user;
  //   } else {
  //     return null;
  //   }
  // }

  SetUserLanguage(newLanguage: string) {
    this.translateService.use(newLanguage);
  }

  Logout() {
    localStorage.removeItem(environment.storageTokenName);
    this.EmptyUser();
    this.router.navigate(['/login']);
  }

  ErrorUser(message: string) {
    const userClaims = new UserClaims({
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      roles: [],
      fullName: null,
      language: null,
      errorMessage: message,
      error: true
    });
    this.user = userClaims;
    this.eventChange();
  }

  EmptyUser() {
    this.user = this.createEmptyUser();
    this.eventChange();
  }

  createEmptyUser() {
    const userClaims = new UserClaims({
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      roles: [],
      fullName: null,
      language: 'pl',
      errorMessage: '',
      error: false
    });

    return userClaims;
  }

  // version from stock

  public get currentUserValue(): UserDto {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/login`, {
        username,
        password
      })
      .pipe(
        map(token => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          const decoded = jwt_decode(token);
          const user = new UserDto();
          user.id = decoded['Id'];
          user.username = decoded['unique_name'];
          user.firstName = decoded['FirstName'];
          user.lastName = decoded['LastName'];
          user.token = token;
          user.fullName = `${user.firstName} ${user.lastName}`;

          user.roles = decoded['role'];
          user.sub = '';

          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }
  loginRemap(email: string) {
    return this.accountService.loginRemap(email).pipe(
      map((token: any) => {
        const decoded = jwt_decode(token);
        const user = new UserDto();
        user.id = decoded['Id'];
        user.username = decoded['unique_name'];
        user.firstName = decoded['FirstName'];
        user.lastName = decoded['LastName'];
        user.token = token;
        user.fullName = `${user.firstName} ${user.lastName}`;
        user.roles = decoded['role'];
        user.sub = '';

        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);

        console.log('API: token remap SUCCESS');
        console.log(user);
        return user;
      })
    );
  }
  // loginRemap(email: string) {
  //   return this.http
  //     .post<any>(`${environment.apiUrl}/Account/TokenRemap`, {
  //       email
  //     })
  //     .pipe(
  //       map(token => {
  //         // store user details and jwt token in local storage to keep user logged in between page refreshes
  //         const decoded = jwt_decode(token);
  //         const user = new UserDto();
  //         user.id = decoded['Id'];
  //         user.username = decoded['unique_name'];
  //         user.firstName = decoded['FirstName'];
  //         user.lastName = decoded['LastName'];
  //         user.token = token;
  //         user.fullName = `${user.firstName} ${user.lastName}`;

  //         user.roles = decoded['role'];
  //         user.sub = '';

  //         localStorage.setItem('currentUser', JSON.stringify(user));
  //         this.currentUserSubject.next(user);

  //         console.log('API: token remap SUCCESS');
  //         console.log(user);
  //         return user;
  //       })
  //     );
  // }

  loginExternal(token: string, claims: Record<string, any>) {
    const decoded = jwt_decode(token);
    const user = new UserDto();
    console.log('loginExternal', decoded, user);
    user.id = 0;
    user.sub = claims.sub;
    user.username = claims.upn;
    user.firstName = claims.given_name;
    user.lastName = claims.family_name;
    user.token = token;
    user.fullName = `${user.firstName} ${user.lastName}`;
    user.aud = claims.aud;

    user.roles = claims.groups.map(item => item.replace(/_/g, ''));

    // checking from API SUB here
    // check if in role specific Drive or FM

    localStorage.setItem('currentUser', JSON.stringify(user));
    console.log('loginExternal', user);
    this.currentUserSubject.next(user);
    // this.afterLogInRedirection(user);
  }

  updateUser(id: number) {
    const current = this.currentUserValue;
    current.id = id;
    localStorage.setItem('currentUser', JSON.stringify(current));
    this.currentUserSubject.next(current);
  }
  updateUserRoles(roles: RoleDto[]) {
    const strRoles: string[] = [];
    roles.forEach((item) => {
      strRoles.push(item.name)
      console.log('API updateUserRoles: push role:', item.name);
    });

    const current = this.currentUserValue;
    current.roles = strRoles;
    localStorage.setItem('currentUser', JSON.stringify(current));
    this.currentUserSubject.next(current);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }
  
  logoutNoRedirect() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
  
  logoutIdentity() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    window.location.href = environment.identityLogoutRedirect;
  }

  afterLogInRedirection(data: UserDto) {
    const roles = data.roles;
    if (Array.isArray(roles)) {
      if (roles.findIndex(r => r.toLowerCase() === 'driver') !== -1) {
        this.router.navigate(['/drivePanel']).then(() => {
          window.location.reload();
        });
      } else if (roles.findIndex(r => r.toLowerCase() === 'admin') !== -1) {
        this.router.navigate(['/business/cars']).then(() => {
          window.location.reload();
        });
      } else if (roles.findIndex(r => r.toLowerCase() === 'adminusers') !== -1) {
        this.router.navigate(['/admin/users']).then(() => {
          window.location.reload();
        });
      } else if (roles.findIndex(r => r.toLowerCase() === 'cic') !== -1) {
        this.router.navigate(['/cic']).then(() => {
          window.location.reload();
        });
      } else if (roles.findIndex(r => r.toLowerCase() === 'dealer') !== -1) {
        this.router.navigate(['/dealer/newCars']).then(() => {
          window.location.reload();
        });
      } else if (roles.findIndex(r => r.toLowerCase() === 'fleetmanager') !== -1) {
        this.router.navigate(['/fleetManager/dashboard']).then(() => {
          window.location.reload();
        });
      } else if (roles.findIndex(r => r.toLowerCase() === 'network') !== -1) {
        this.router.navigate(['/network']).then(() => {
          window.location.reload();
        });
      } else {
        this.router.navigate(['/dashboard']).then(() => {
          window.location.reload();
        });
      }
    } else {
      if (roles === 'Driver') {
        this.router.navigate(['/drivePanel']).then(() => {
          window.location.reload();
        });
      } else if (roles === 'Admin') {
        this.router.navigate(['/business/cars']).then(() => {
          window.location.reload();
        });
      } else if (roles === 'AdminUsers') {
        this.router.navigate(['/admin/users']).then(() => {
          window.location.reload();
        });
      } else if (roles === 'Cic') {
        this.router.navigate(['/cic']).then(() => {
          window.location.reload();
        });
      } else if (roles === 'Dealer') {
        this.router.navigate(['/dealer/newCars']).then(() => {
          window.location.reload();
        });
      } else if (roles === 'FleetManager') {
        this.router.navigate(['/fleetManager/dashboard']).then(() => {
          window.location.reload();
        });
      } else if (roles === 'Network') {
        this.router.navigate(['/network']).then(() => {
          window.location.reload();
        });
      } else {
        this.router.navigate(['/dashboard']).then(() => {
          window.location.reload();
        });
      }
    }

    // console.log(this.returnUrl);
    // // depends on which user is in role then redirect to proper default page
    // this.router.navigate([this.returnUrl]);

  }
}
