import { Role } from "./Role";

export class UserClaims {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: Role[];
  fullName: string;
  language: string;
  errorMessage: string;
  error: boolean;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
