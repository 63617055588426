import {Component, OnInit, ElementRef} from '@angular/core';
import {ROUTES} from '../sidebar/sidebar.component';
import {Location} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '../../_services/authentication.service';
import {UserClaims} from '../../_models/user-claims';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../_services/language.service';
import {LanguageResponseDto} from '../../_models/dtos/language-response-dto';
import {UserDto} from 'src/app/_models/account/user-dto';
import {filter} from 'rxjs/operators';
import {GlobalService} from '../../_services/global.service';
import {OauthService} from '../../_services/oauth.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  private sidebarVisible: boolean;
  user: UserDto;
  languages: LanguageResponseDto[];

  public navState: string;
  public navGroups: INavigationGroup[] = [];
  public selectedNavGroup: INavigationGroup = null;
  private currentRoute: string;
  public isFmRole = false;
  public fmAwaitingRequests = false;


  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private global: GlobalService,
    private oauthService: OauthService
  ) {
    this.location = location;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(item => {
      this.user = item;

      if (this.user !== null) {
        this.createMenuItems();
      }
    });

    this.languageService.getLanguageDictionary().subscribe(
      (result: LanguageResponseDto[]) => {
        this.languages = result;
      },
      error => {
        console.log(error);
      }
    );
  }

  getTitle() {
    let title = this.location.prepareExternalUrl(this.location.path());
    if (title.charAt(0) === '#') {
      title = title.slice(2);
    }
    title = title.split('/').pop();

    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === title) {
        return this.listTitles[item].title;
      }
    }
    // return 'Dashboard';
    return '';
  }

  logOut() {
    let identityUser = false;
    const isExternal = localStorage.getItem('isExternal');
    var externalFlag = isExternal == "1";
   // console.log('oauth logout: is external flag: ' + externalFlag);

    this.authenticationService.currentUser.subscribe(item => {

      if (item !== null && item.sub !== null && item.sub.length > 0) {
        identityUser = true;
        //console.log('oauth logout 1');
        this.oauthService.logOut();
        if (isExternal) {
          localStorage.removeItem("isExternal");
        }
      } else if (externalFlag) {
        identityUser = true;
        //console.log('oauth logout 2');
        this.oauthService.logOut();
        localStorage.removeItem("isExternal");
      }
    });

    if (identityUser) {
      console.log('oauth logout: redirect');
      this.authenticationService.logoutIdentity();
    } else {
      this.authenticationService.logout();
      this.global.timerSubscription.unsubscribe();
    }

  }

  setLanguage(lang: string) {
    this.translateService.use(lang.toLowerCase());
  }

  editProfile() {
    this.router.navigate(['/account/edit']).then(() => {
      window.location.reload();
    });
  }

  private setMenuGroupFromRoute(): void {
    this.currentRoute = this.router.url;
    const route = `.${this.currentRoute}`;

    this.navGroups.forEach((group: INavigationGroup) => {
      const index = group.elements.findIndex(x => x.route === route);
      if (index !== -1) {
        group.elements[index].selected = true;
        this.selectedNavGroup = group;
      }
    });
  }

  createMenuItems(): void {
    this.selectedNavGroup = null;
    const navGroups: INavigationGroup[] = [];

    const dashboard: INavigationGroup = {
      id: 'dashboard',
      name: 'sidebar.dashboard',
      expanded: false,
      expandable: false,
      elements: [],
      route: './dashboard',
      allowedRoles: ['Admin', 'FleetManager', 'Driver', 'Network', 'Dealer', 'Cic', 'AdminUsers', 'Assistance'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const driver: INavigationGroup = {
      id: 'drive',
      name: 'sidebar.driverPanel',
      expanded: false,
      expandable: false,
      elements: [],
      route: './drivePanel',
      allowedRoles: ['Driver'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };
    //
    // const dealerElements: INavigationElement[] = [
    //   {
    //     name: 'sidebar.carDealer',
    //     route: './dealer/newCars',
    //     selected: false,
    //     allowedRoles: ['Dealer'],
    //     allowed: false,
    //     spaRoute: true
    //   }
    // ];

    const dealer: INavigationGroup = {
      id: 'dealer',
      name: 'sidebar.dealer',
      expanded: false,
      expandable: true,
      elements: [],
      route: './dealer/newCars',
      allowedRoles: ['Dealer'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };
    //
    // const fleetManagerElements: INavigationElement[] = [
    //   {
    //     name: 'sidebar.dashboard',
    //     route: './fleetManager/dashboard',
    //     selected: false,
    //     allowedRoles: ['FleetManager'],
    //     allowed: false,
    //     spaRoute: true
    //   },
    //   {
    //     name: 'sidebar.reports',
    //     route: './fleetManager/reports',
    //     selected: false,
    //     allowedRoles: ['FleetManager'],
    //     allowed: false,
    //     spaRoute: true
    //   },
    //   {
    //     name: 'sidebar.dataImport',
    //     route: './fleetManager/import',
    //     selected: false,
    //     allowedRoles: ['FleetManager'],
    //     allowed: false,
    //     spaRoute: true
    //   },
    // ];


    const fleetManager: INavigationGroup = {
      id: 'fleetManager',
      name: 'sidebar.fleetManager',
      expanded: true,
      expandable: true,
      elements: [],
      route: './fleetManager/dashboard',
      allowedRoles: ['FleetManager'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const fmReports: INavigationGroup = {
      id: 'fmReports',
      name: 'sidebar.reports',
      expanded: false,
      expandable: true,
      elements: [],
      route: './fleetManager/reports',
      allowedRoles: ['FleetManager'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const fmImports: INavigationGroup = {
      id: 'fmImports',
      name: 'sidebar.dataImport',
      expanded: false,
      expandable: true,
      elements: [],
      route: './fleetManager/import',
      allowedRoles: ['FleetManager'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const fmRequestsForAcceptance: INavigationGroup = {
      id: 'fmRequestsForAcceptance',
      name: 'sidebar.requestsForAcceptance',
      expanded: false,
      expandable: true,
      elements: [],
      route: './fleetManager/request',
      allowedRoles: ['FleetManager'],
      allowed: false,
      condition: true,
      conditionVariable: this.fmAwaitingRequests,
    };

    const fmDrivers: INavigationGroup = {
      id: 'fmDrivers',
      name: 'sidebar.drivers',
      expanded: false,
      expandable: true,
      elements: [],
      route: './fleetManager/drivers',
      allowedRoles: ['FleetManager'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const network: INavigationGroup = {
      id: 'network',
      name: 'sidebar.network',
      expanded: false,
      expandable: false,
      elements: [],
      route: './network',
      allowedRoles: ['Network'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const cic: INavigationGroup = {
      id: 'cic',
      name: 'sidebar.cic',
      expanded: false,
      expandable: false,
      elements: [],
      route: './cic',
      allowedRoles: ['Cic'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const assistance: INavigationGroup = {
      id: 'assistance',
      name: 'sidebar.assistance',
      expanded: false,
      expandable: false,
      elements: [],
      route: './assistance',
      allowedRoles: ['Assistance'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const businessImportExportElements: INavigationElement[] = [
      {
        name: 'sidebar.dataImport',
        route: './business/import',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
      {
        name: 'sidebar.exportRelocation',
        route: './business/export',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
      {
        name: 'sidebar.exportOrders',
        route: './business/tires-orders',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
      {
        name: 'sidebar.exportMessages',
        route: './business/messages',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
    ];

    const businessImportExport: INavigationGroup = {
      id: 'businessImportExport',
      name: 'sidebar.importExport',
      expanded: true,
      expandable: true,
      elements: businessImportExportElements,
      route: './business/import',
      allowedRoles: ['Admin'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const businessCarsElements: INavigationElement[] = [
      {
        name: 'sidebar.vehicles',
        route: './business/cars',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
      // {
      //   name: 'sidebar.companyCars',
      //   route: './business/companyCars',
      //   selected: false,
      //   allowedRoles: ['Admin'],
      //   allowed: false,
      //   spaRoute: true
      // },
      {
        name: 'sidebar.carDealer',
        route: './business/dealerCars',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
    ];

    const businessCars: INavigationGroup = {
      id: 'businessCars',
      name: 'sidebar.vehicles',
      expanded: true,
      expandable: true,
      elements: businessCarsElements,
      route: './business/cars',
      allowedRoles: ['Admin'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const businessManagementElements: INavigationElement[] = [
      {
        name: 'sidebar.clients',
        route: './business/clients',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
      {
        name: 'sidebar.workshops',
        route: './business/workshops',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
      {
        name: 'sidebar.orders',
        route: './business/orders',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
      {
        name: 'sidebar.reports',
        route: './business/reports',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
      // {
      //   name: 'sidebar.messages',
      //   route: './business/messages',
      //   selected: false,
      //   allowedRoles: ['Admin'],
      //   allowed: false,
      //   spaRoute: true
      // },
    ];

    const businessManagement: INavigationGroup = {
      id: 'businessManagement',
      name: 'sidebar.management',
      expanded: true,
      expandable: true,
      elements: businessManagementElements,
      route: './business/clients',
      allowedRoles: ['Admin'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const businessSystemElements: INavigationElement[] = [
      {
        name: 'configuration.tabs.configurationKeys',
        route: './business/configuration',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
      {
        name: 'configuration.tabs.tireSuppliers',
        route: './business/supplier',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
      {
        name: 'sidebar.season',
        route: './business/season',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
      {
        name: 'sidebar.cic',
        route: './business/cic',
        selected: false,
        allowedRoles: ['Admin'],
        allowed: false,
        spaRoute: true
      },
    ];

    const businessSystem: INavigationGroup = {
      id: 'businessSystem',
      name: 'sidebar.configuration',
      expanded: true,
      expandable: true,
      elements: businessSystemElements,
      route: './business/configuration',
      allowedRoles: ['Admin'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    const adminItElements: INavigationElement[] = [
      {
        name: 'sidebar.users',
        route: './admin/users',
        selected: false,
        allowedRoles: ['AdminUsers'],
        allowed: false,
        spaRoute: true
      },
      {
        name: 'sidebar.reports',
        route: './admin/reports',
        selected: false,
        allowedRoles: ['AdminUsers'],
        allowed: false,
        spaRoute: true
      },
    ];

    const adminIt: INavigationGroup = {
      id: 'adminIt',
      name: 'sidebar.adminIt',
      expanded: true,
      expandable: true,
      elements: adminItElements,
      route: './admin/users',
      allowedRoles: ['AdminUsers'],
      allowed: false,
      condition: false,
      conditionVariable: undefined,
    };

    // navGroups.push(dashboard);
    navGroups.push(driver);
    navGroups.push(dealer);
    navGroups.push(fleetManager);
    navGroups.push(fmReports);
    navGroups.push(fmImports);
    navGroups.push(fmRequestsForAcceptance);
    // navGroups.push(fmDrivers);
    navGroups.push(network);
    navGroups.push(cic);
    navGroups.push(assistance);
    navGroups.push(businessCars);
    navGroups.push(businessImportExport);
    navGroups.push(businessManagement);
    navGroups.push(businessSystem);
    navGroups.push(adminIt);

    this.navGroups = navGroups;

    this.setMenuGroupFromRoute();
    this.hideMenuGroups();

  }

  private hideMenuGroups(): void {
    const navGroupToRemove = [];

    this.navGroups.forEach((group: INavigationGroup) => {
      let remove = true;
      const roles: string[] = [];
      if (this.user !== null && this.user !== undefined) {
        if (this.user.roles instanceof Array) {
          roles.push(...this.user.roles);
        } else {
          roles.push(this.user.roles);
        }
        // check if role is FM for notification stuff
        const fmRole = roles.findIndex(x => x.toLowerCase() === 'fleetmanager');
        this.isFmRole = fmRole !== -1;
      }
      group.allowedRoles.forEach(role => {
        if (roles.findIndex(x => x === role) !== -1) {
          remove = false;
        }
      });

      if (remove) {
        navGroupToRemove.push(group);
      }
    });

    navGroupToRemove.forEach((group: INavigationGroup) => {
      const index = this.navGroups.findIndex(x => x.id === group.id);
      if (index !== -1) {
        this.navGroups.splice(index, 1);
      }
    });
    this.hideMenuGroupElements();
  }

  private hideMenuGroupElements(): void {
    // removing elements in menu
    const roles: string[] = [];
    if (this.user.roles instanceof Array) {
      roles.push(...this.user.roles);
    } else {
      roles.push(this.user.roles);
    }
    this.navGroups.forEach((group: INavigationGroup) => {
      const navElementToRemove = [];
      group.elements.forEach((element: INavigationElement) => {
        let remove = true;
        element.allowedRoles.forEach(role => {
          if (roles.findIndex(x => x === role) !== -1) {
            remove = false;
          }
        });

        if (remove) {
          navElementToRemove.push(element);
        }
      });

      navElementToRemove.forEach((element: INavigationElement) => {
        const index = group.elements.findIndex(x => x.name === element.name);
        if (index !== -1) {
          group.elements.splice(index, 1);
        }
      });
    });
  }

  selectMenu(id: string): void {
    const currentSelected = this.selectedNavGroup;
    this.selectedNavGroup = null;
    const index = this.navGroups.findIndex(x => x.id === id);

    if (index !== -1) {
      this.selectedNavGroup = this.navGroups[index];
    }
  }

  awaitingRequests(anyRequests: boolean) {
    console.log(anyRequests);
    this.fmAwaitingRequests = anyRequests;
  }
}


export interface INavigationElement {
  name: string;
  route: string;
  selected: boolean;
  allowedRoles: string[];
  allowed: boolean;
  spaRoute: boolean;
}

export interface INavigationGroup {
  id: string;
  name: string;
  elements: INavigationElement[];
  expanded: boolean;
  expandable: boolean;
  route: string;
  allowedRoles: string[];
  allowed: boolean;
  condition: boolean;
  conditionVariable: any;
}
